var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"tw-mb-1 tw-text-sm tw-text-black"},[_vm._v("Working hours")]),_c('div',{staticClass:"tw-mb-2 tw-text-xs tw-text-dark-gray"},[_vm._v(" Only autofill availability between working hours ")]),_c('v-switch',{attrs:{"id":"working-hours-toggle","inset":"","input-value":_vm.workingHours.enabled,"hide-details":""},on:{"change":(val) => _vm.updateWorkingHours('enabled', val)},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"tw-text-sm tw-text-black"},[_c('div',{staticClass:"tw-flex tw-items-center tw-gap-2"},[_c('v-select',{staticClass:"-tw-mt-0.5 tw-w-20 tw-text-xs",attrs:{"menu-props":"auto","dense":"","hide-details":"","items":_vm.times,"value":_vm.workingHours.startTime},on:{"input":(val) => _vm.updateWorkingHours('startTime', val),"click":(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}}),_c('div',[_vm._v("to")]),_c('v-select',{staticClass:"-tw-mt-0.5 tw-w-20 tw-text-xs",attrs:{"menu-props":"auto","dense":"","hide-details":"","items":_vm.times,"value":_vm.workingHours.endTime},on:{"input":(val) => _vm.updateWorkingHours('endTime', val),"click":(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}})],1)])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }